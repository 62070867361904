import React, { useState, useEffect } from "react";
import { CpLoader } from "canopy-styleguide!sofe";
import { getDocument } from "../documents.resource.js";
import { getFileCategory } from "../../common/mime-types.helpers.js";
import PdfPage from "../preview/pdf-page.component.js";

const renderTypes = { NO_PREVIEW: 0, PDF: 1, IMAGE: 2 };

export function InlineDocumentPreview(props) {
  const [document, setDocument] = useState(null);
  const [renderType, setRenderType] = useState(renderTypes.NO_PREVIEW);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const subscription = getDocument(props.documentId).subscribe((result) => {
      setDocument(result.file);
      const file = result.file;
      const documentType = getFileCategory(file.mimetype);
      if (!documentType || !file.preview_links || !file.preview_links.length) {
        setRenderType(renderTypes.NO_PREVIEW);
      } else if (documentType === "image") {
        setRenderType(renderTypes.IMAGE);
      } else if (["pdf", "presentation", "spreadsheet", "word-doc"].includes(documentType)) {
        setRenderType(renderTypes.PDF);
      } else {
        setRenderType(renderTypes.NO_PREVIEW);
      }
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [props.documentId]);

  return document && !loading ? (
    <>
      {renderType === renderTypes.NO_PREVIEW && (
        <div className="no-preview">
          <img src="https://cdn.canopytax.com/static/docs-ui/doc-preview-blank.svg" alt="Preview Not Available" />
          <div>Preview not available</div>
        </div>
      )}
      {renderType === renderTypes.PDF &&
        document.preview_links.map((link, i) => (
          <div className="cps-margin-bottom-16 cps-card" key={i}>
            <PdfPage src={link} style={{ maxWidth: "100%" }} />
          </div>
        ))}
      {renderType === renderTypes.IMAGE &&
        document.preview_links.map((link, i) => <img key={i} src={link} style={{ maxWidth: "100%" }} />)}
    </>
  ) : (
    <CpLoader />
  );
}
