import { findKey, isEmpty } from "lodash";

// Map of file categories to a list of mime type/extension substrings to search for
export const fileCategories = {
  audio: ["audio/"],
  code: [
    "text/css",
    "text/html",
    "text/javascript",
    "application/javascript",
    "text/x-python-script",
    "application/java",
    "text/x-c",
    "text/xml",
    "application/xml",
  ],
  image: [
    "svg",
    "gif",
    "png",
    "jpeg",
    "heic",
    "jpg",
    "jp2",
    "bmp",
    "tif",
    "tiff",
    "emf",
    "cur",
    "dib",
    "ico",
    "pbm",
    "pgm",
    "ppm",
    "psb",
    "psd",
    "image/svg+xml",
    "image/gif",
    "image/png",
    "image/jpeg",
    "image/heic",
    "image/jp2",
    "image/bmp",
    "image/tif",
    "image/tiff",
    "image/emf",
  ],
  pdf: ["application/pdf", "pdf"],
  presentation: [
    "ms-powerpoint",
    "mspowerpoint",
    "ppt",
    "pptx",
    "officedocument.presentation",
    "iwork-keynote",
    "keynote",
    "opendocument.presentation",
    "fodp",
    "pot",
    "potm",
    "potx",
    "pps",
    "ppsm",
    "ppsx",
    "otp",
  ],
  "web-link": ["application/link-format"],
  spreadsheet: [
    "opendocument.spreadsheet",
    "officedocument.spreadsheet",
    "ms-excel",
    "msexcel",
    "excel",
    "ms_excel",
    "xls",
    "xlsx",
    "iwork-numbers",
    "numbers",
    "fods",
    "ods",
    "ots",
    "xlt",
    "xltm",
    "xltx",
  ],
  video: ["video/", "video/mp4", "video/quicktime", "mkv"],
  "word-doc": [
    "ms-word",
    "msword",
    "officedocument.wordprocessing",
    "opendocument.text",
    "iwork-pages",
    "pages",
    "docx",
    "doc",
    "fodt",
    "dot",
    "dotm",
    "dotx",
    "odt",
    "rtf",
    "msg",
  ],
  zip: ["application/zip", "application/gzip", "application/x-zip-compressed", "zip"],
  other: ["eml", "odf", "odg", "vdx", "vsd", "vsdm", "vsdx", "xhtm", "xhtml", "xod", "xps", "txt"],
};

const categoryColors = {
  pdf: "var(--cps-color-mandy)",
  spreadsheet: "var(--cps-color-green)",
  "word-doc": "var(--cps-color-vibrant-ocean)",
};

export function getFileCategory(mimetype) {
  if (isEmpty(mimetype)) return null;
  return findKey(fileCategories, (searchStrings) => searchStrings.includes(mimetype.toLowerCase()));
}

export function getCategoryColor(category, defaultColor = "#777777") {
  return categoryColors[category] || defaultColor;
}

export function isImage(type) {
  return getFileCategory(type) === "image";
}

export function isPdf(type) {
  return getFileCategory(type) === "pdf";
}
