// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-documents-save-to-canopy-files-dropdown-create-folder-page-create-folder-header-styles__header--mx9E2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.8rem;
  padding: 0 0.8rem 0 1.6rem;
  border-bottom: 0.1rem solid var(--cps-color-silver)
}

.src-documents-save-to-canopy-files-dropdown-create-folder-page-create-folder-header-styles__left--KK25A {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.src-documents-save-to-canopy-files-dropdown-create-folder-page-create-folder-header-styles__right--SFgpX {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.src-documents-save-to-canopy-files-dropdown-create-folder-page-create-folder-header-styles__title--QTOME {
  font-size: 1.6rem;
  color: var(--cps-color-cool-gray)
}`, "",{"version":3,"sources":["webpack://./src/documents/save-to-canopy-files-dropdown/create-folder-page/create-folder-header.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,0BAA0B;EAC1B;AACF;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB;AACF","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 4.8rem;\n  padding: 0 0.8rem 0 1.6rem;\n  border-bottom: 0.1rem solid var(--cps-color-silver)\n}\n\n.left {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.right {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.title {\n  font-size: 1.6rem;\n  color: var(--cps-color-cool-gray)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `src-documents-save-to-canopy-files-dropdown-create-folder-page-create-folder-header-styles__header--mx9E2`,
	"left": `src-documents-save-to-canopy-files-dropdown-create-folder-page-create-folder-header-styles__left--KK25A`,
	"right": `src-documents-save-to-canopy-files-dropdown-create-folder-page-create-folder-header-styles__right--SFgpX`,
	"title": `src-documents-save-to-canopy-files-dropdown-create-folder-page-create-folder-header-styles__title--QTOME`
};
export default ___CSS_LOADER_EXPORT___;
