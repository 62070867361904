import React, { useContext } from "react";
import PropTypes from "prop-types";
import styles from "./create-folder-footer.styles.css";
import { CpButton } from "canopy-styleguide!sofe";
import SaveToCanopyFilesDropdownContext from "src/documents/save-to-canopy-files-dropdown/save-to-canopy-files-dropdown.context";

CreateFolderFooter.propTypes = {
  isCreatingFolder: PropTypes.bool.isRequired,
  setIsCreatingFolder: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default function CreateFolderFooter(props) {
  const dispatch = useContext(SaveToCanopyFilesDropdownContext);

  return (
    <div className={styles.footer}>
      <CpButton
        btnType="primary"
        disabled={props.error || props.sCreatingFolder}
        onClick={() => props.setIsCreatingFolder(true)}
      >
        Create
      </CpButton>
      <CpButton btnType="flat" onClick={() => dispatch({ type: "create_folder_cancelled" })}>
        Cancel
      </CpButton>
    </div>
  );
}
