import React from "react";
import { CpModal, CpEmptyState, CpButton } from "canopy-styleguide!sofe";
import { DateTime } from "luxon";

export default function FileLockedModal({ file, onSubmit }) {
  return (
    <>
      <CpModal.Header />
      <CpModal.Body>
        <div className="cp-p-16">
          <CpEmptyState
            img="es_caution"
            text="You don't have permission to unlock this file."
            subText={`${file.locked_by_user_full_name} opened this file for editing ${DateTime.fromISO(file.locked_at, {
              zone: "utc",
            })
              .toLocal()
              .toRelative()}.
              You can edit this file once it becomes unlocked.`}
          />
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={onSubmit} className="cp-mr-8">
          Okay
        </CpButton>
      </CpModal.Footer>
    </>
  );
}
