import React from "react";
import { CpModal, CpEmptyState, CpButton } from "canopy-styleguide!sofe";

export default function AnnotationsLostModal({ onSubmit }) {
  return (
    <>
      <CpModal.Header />
      <CpModal.Body>
        <div className="cp-p-16">
          <CpEmptyState
            img="es_caution"
            text="Annotations on this file will be lost by editing."
            subText={`Because  the editing software for this file type does not support annotations, the applied annotations will be removed if you continue.`}
          />
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={() => onSubmit(true)} className="cp-mr-8">
          Continue
        </CpButton>
        <CpButton btnType="flat" onClick={() => onSubmit(false)}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </>
  );
}
