import { keyBy, get } from "lodash";

let userMap;

export function setUsers(users) {
  userMap = keyBy(users, "id");
}

export function toUserName(id) {
  return get(userMap, `${id}.name`, "Canopy User");
}
