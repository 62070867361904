import React, { useContext } from "react";
import PropTypes from "prop-types";
import styles from "./rename-file-footer.styles.css";
import { CpButton } from "canopy-styleguide!sofe";
import SaveToCanopyFilesDropdownContext from "src/documents/save-to-canopy-files-dropdown/save-to-canopy-files-dropdown.context";

RenameFileFooter.propTypes = {
  isRenamingFile: PropTypes.bool.isRequired,
  setIsRenamingFile: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default function RenameFileFooter(props) {
  const dispatch = useContext(SaveToCanopyFilesDropdownContext);

  return (
    <div className={styles.footer}>
      <CpButton
        btnType="primary"
        disabled={props.error || props.isRenamingFile}
        onClick={() => props.setIsRenamingFile(true)}
      >
        Rename
      </CpButton>
      <CpButton btnType="flat" onClick={() => dispatch({ type: "rename_file_cancelled" })}>
        Cancel
      </CpButton>
    </div>
  );
}
