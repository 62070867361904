import React, { useReducer, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./save-to-canopy-files-dropdown.styles.css";
import { a } from "kremling";
import { CpButton, CpTooltip } from "canopy-styleguide!sofe";
import SaveToCanopyFilesDropdownContext from "./save-to-canopy-files-dropdown.context";
import { initialState, reducer, refreshContentTypes } from "./save-to-canopy-files-dropdown.reducer";
import ContentExplorerPage from "./content-explorer-page/content-explorer-page.component";
import CreateFolderPage from "./create-folder-page/create-folder-page.component";
import RenameFilePage from "./rename-file-page/rename-file-page.component";
import SavedConfirmationPage from "./saved-confirmation-page/saved-confirmation-page.component";
import { getListOfFilesInFolder, copyFiles } from "src/global-files/resources/files.resource";
import { handleError } from "src/handle-error.helper";
import { finalize, map } from "rxjs/operators";
import { sortBy } from "lodash";
import { warningToast } from "toast-service!sofe";
import { track } from "cp-analytics";
import { useHasAccess } from "cp-client-auth!sofe";

SaveToCanopyFilesDropdown.propTypes = {
  // todo remove contactId after migration
  contactId: PropTypes.number,
  clientId: PropTypes.number,
  docId: PropTypes.string,
  saveHandler: PropTypes.func,
  iconClass: PropTypes.string,
  fileOrigin: PropTypes.string,
};

export default function SaveToCanopyFilesDropdown({
  clientId,
  // todo remove contactId after migration
  contactId,
  docId,
  saveHandler,
  iconClass,
  fileOrigin,
  previewer,
}) {
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  useEffect(() => {
    if (state.isOpen) {
      window.addEventListener("click", close);
      return () => window.removeEventListener("click", close);
    }
    function close() {
      dispatch({ type: "close_dropdown" });
    }
  }, [state.isOpen]);

  useEffect(() => {
    // todo remove contactId after migration
    if ((clientId || contactId) && !state.clientId) {
      // todo remove contactId after migration
      dispatch({ type: "change_client_id", payload: clientId || contactId });
    }
    // todo remove contactId after migration
  }, [clientId, contactId, state.clientId]);

  useEffect(() => {
    if ((state.refreshContent && state.savedFolderId) || state.folderId) {
      if (state.refreshContent === refreshContentTypes.HARD) {
        dispatch({
          type: "set_folder_contents",
          payload: {
            folders: null,
            files: null,
            path: [],
          },
        });
      }

      const subscription = getListOfFilesInFolder(state.savedFolderId || state.folderId)
        .pipe(
          finalize(() => {
            dispatch({ type: "contents_refreshed" });
          })
        )
        .subscribe((folderData) => {
          dispatch({
            type: "set_folder_contents",
            payload: {
              folders: sortBy(
                folderData.files.filter((item) => item.mimetype === "folder"),
                "name"
              ),
              files: sortBy(
                folderData.files.filter((item) => item.mimetype !== "folder"),
                "name"
              ),
              path: folderData.folder_data.path,
            },
          });
        }, handleError);

      return () => subscription && subscription.unsubscribe();
    }
  }, [state.refreshContent, state.folderId, state.savedFolderId]);

  useEffect(() => {
    if (state.isSavingFile) {
      if (!docId && !saveHandler) {
        throw Error(`Required information to save the file is missing.`);
      }

      let observable;

      if (saveHandler) {
        observable = saveHandler(state.savedFolderId);
      } else {
        observable = copyFiles([docId], state.savedFolderId).pipe(
          map((response) => {
            if (response.files && response.files.length) {
              return response.files[0];
            }
          })
        );
      }

      const subscription = observable.subscribe((file) => {
        if (file) {
          trackSave(fileOrigin, file.mimetype);
          dispatch({ type: "save_file_completed", payload: file });
        } else {
          warningToast(
            "An unexpected issue occurred while saving the file.  Please verify the file has saved or try again."
          );
          dispatch({ type: "save_file_errored" });
        }
      }, handleError);

      return () => subscription && subscription.unsubscribe();
    }
  }, [state.isSavingFile, docId, state.savedFolderId, fileOrigin, saveHandler]);

  function handleButtonClick(e) {
    e.stopPropagation();

    if (state.isOpen) {
      dispatch({ type: "close_dropdown" });
    } else {
      dispatch({ type: "open_dropdown" });
    }
  }

  const hasFilesAccess = useHasAccess("files");
  const clientSelectEnabled = useMemo(() => previewer, [previewer]);
  if (!hasFilesAccess) {
    return null;
  }

  return (
    <SaveToCanopyFilesDropdownContext.Provider value={dispatch}>
      <CpTooltip text="Save to Canopy files">
        <CpButton icon="folder-c" aria-label="Save to Canopy files" onClick={handleButtonClick} className={iconClass} />
      </CpTooltip>
      <div className={a("cps-dropdown", styles.component).m("cps-open", state.isOpen)}>
        {state.isOpen && (
          <div
            className={a("cps-dropdown-menu", "cps-accessible-important", styles.dropdown)}
            onClick={(e) => e.stopPropagation()}
          >
            {state.page === "contentExplorer" && (
              <ContentExplorerPage
                docId={docId}
                saveHandler={saveHandler}
                path={state.path}
                folders={state.folders}
                files={state.files}
                isSavingFile={state.isSavingFile}
                // todo remove contactId after migration
                clientId={state.clientId || clientId || contactId}
                clientSelectEnabled={clientSelectEnabled}
              />
            )}
            {state.page === "createFolder" && (
              <CreateFolderPage
                // todo remove contactId after migration
                clientId={state.clientId || clientId || contactId}
                folderId={state.folderId}
                folders={state.folders}
              />
            )}
            {state.page === "renameFile" && (
              <RenameFilePage selectedFile={state.selectedFile} files={state.files} fileOrigin={fileOrigin} />
            )}
            {state.page === "savedConfirmation" && (
              <SavedConfirmationPage
                path={state.path}
                selectedFile={state.selectedFile}
                // todo remove contactId after migration
                clientId={state.clientId || clientId || contactId}
              />
            )}
          </div>
        )}
      </div>
    </SaveToCanopyFilesDropdownContext.Provider>
  );
}

function trackSave(fileOrigin, fileType) {
  if (fileOrigin && fileType && typeof fileOrigin === "string" && typeof fileType === "string") {
    track("practice_management", "files", "save_to_canopy_file_saved", {
      file_origin: fileOrigin,
      file_type: fileType,
    });
  }
}
