import "./set-public-path.js";
import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import singleSpaCanopy from "single-spa-canopy";
import { property } from "lodash";
import * as VirusHelper from "src/virus-dialog/virus.helpers";
import { featureEnabled } from "feature-toggles!sofe";
import "./tailwind.scss";

function getLinkedFileList() {
  return import(
    /* webpackChunkName: "linked-file-list" */ "src/global-files/inbox/cards/linked-file-list.component.js"
  );
}

export function getPreviewer() {
  return import(/* webpackChunkName: "preview" */ "src/documents/preview/preview.component.js");
}

export { SaveToCanopyFilesDropdownParcel } from "src/documents/save-to-canopy-files-dropdown/save-to-canopy-files-dropdown.parcel";
export { VirusModalParcel } from "src/virus-dialog/virus-modal.parcel";
export { uploadFiles } from "src/global-files/resources/files.resource.js";
export const LinkedFileListLazy = React.lazy(() => getLinkedFileList());
export const isFileSafeToOpen = (fileId: string | number, purpose: string) =>
  VirusHelper.isFileSafeToOpen(fileId, purpose);
export { truncateFilename } from "src/common/file.helpers.js";
export { InlineDocumentPreview } from "src/documents/simple-preview/inline-document-preview.component.js";

export const Preview = React.lazy(() => getPreviewer());

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  loadRootComponent: () => import(/* webpackChunkName: "root-routes" */ "./root.routes.js").then(property("default")),
  domElementGetter,
});

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  childAppName: "docs-ui",
  mainContentTransition: false,
});

export const bootstrap = [canopyLifecycles.bootstrap, reactLifecycles.bootstrap];

export const mount = [reactLifecycles.mount, canopyLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

function domElementGetter() {
  const docsUi = document.getElementById("docs-ui");
  if (docsUi) return docsUi;

  // If the element doesn't exist then create it
  const container = document.createElement("div");
  container.setAttribute("id", "docs-ui");
  document.body.appendChild(container);
  return container;
}

export function loadInboxDrawer() {
  return import(/* webpackChunkName: "inbox-drawer" */ "src/global-files/inbox/inbox-drawer.component").then(
    (module) => module.inboxDrawer
  );
}

export function loadFilePickerModal() {
  return import(/* webpackChunkName: "file-picker" */ "src/global-files/file-picker-modal.component.js").then(
    (module) => module.filePickerModal
  );
}

export function LinkedFileCardFn() {
  return import(
    /* webpackChunkName: "linked-file-card" */ "src/global-files/inbox/cards/linked-file-card.component.js"
  );
}

export function loadDocIcon() {
  return import(/* webpackChunkName: "doc-icon" */ "src/documents/list/document/doc-icon.component.js").then(
    (module) => module
  );
}

export function loadFolderTemplatesModal() {
  const importFolderTemplateModal = featureEnabled("toggle_files_folder_template_modal")
    ? import(
        /* webpackChunkName: "folder-templates-modal" */ "src/folder-templates/folder-template-modal.component"
        // ).then((module) => module.createFolderTemplateModal)
      ).then((module) => module.FolderTemplateModal)
    : import(
        /* webpackChunkName: "folder-templates-modal" */ "src/folder-templates/folder-templates-modal-deprecated.component"
      ).then((module) => module.folderTemplatesModalDeprecated);

  return importFolderTemplateModal;
}

export async function loadUploadFilesHelper() {
  const { uploadFilesAsync } = await import(
    /* webpackChunkName: "upload-files-helper" */ "./global-files/resources/upload-files.helpers.js"
  );
  return { uploadFilesAsync };
}

export function loadDropListParcel() {
  return import(/* webpackChunkName: "drop-list-parcel" */ "./documents/list/drop-list.wrapper.js").then(
    (module) => module.DropListParcel
  );
}

export function dropListChunk() {
  return import(/* webpackChunkName: "drop-list" */ "./documents/list/drop-list.js");
}

export function saveToCanopyFiles() {
  return import(
    /* webpackChunkName: "save-to-canopy-files" */ "./documents/save-to-canopy-files-dropdown/save-to-canopy-files-dropdown.js"
  );
}

export function NewFileUploadParcel() {
  return import(/* webpackChunkName: "upload-files-button-parcel" */ "./common/new-file-upload.parcel.js").then(
    (mod) => mod.NewFileUploadButtonParcel
  );
}

export async function loadFoldersAndFiles() {
  return await import(/*  webpackChunkName: "folders-and-files" */ "./files-rebuild/folders-and-files.js");
}

export async function AddFileButton() {
  return await import(
    /* webpackChunkName: "add-file-button" */ "./global-files/open-file-picker/add-file-popup-button.js"
  );
}

export const getDocumentSettings = async () => {
  return await import(/* webpackChunkName: "document-settings" */ "src/document-settings/document-settings-wrapper.js");
};
