import React, { useContext } from "react";
import PropTypes from "prop-types";
import styles from "./saved-confirmation-body.styles.css";
import { CpButton } from "canopy-styleguide!sofe";
import SaveToCanopyFilesDropdownContext from "src/documents/save-to-canopy-files-dropdown/save-to-canopy-files-dropdown.context";
import DocIcon from "src/documents/list/document/doc-icon.component";
import { noExt } from "src/documents/documents.helpers";

SavedConfirmationBody.propTypes = {
  selectedFile: PropTypes.object.isRequired,
};

export default function SavedConfirmationBody(props) {
  const dispatch = useContext(SaveToCanopyFilesDropdownContext);

  return (
    <div className={styles.body}>
      <div className={styles.left}>
        <DocIcon doc={props.selectedFile} />
        <span className={styles.name}>{noExt(props.selectedFile.name)}</span>
      </div>
      <div className={styles.right}>
        <CpButton
          icon="crud-pencil"
          aria-label="Rename file"
          onClick={() => dispatch({ type: "rename_file", payload: props.selectedFile })}
        />
      </div>
    </div>
  );
}
