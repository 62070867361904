import React from "react";
import { CpModal, CpEmptyState, CpButton } from "canopy-styleguide!sofe";
import { DateTime } from "luxon";

export default function FileUnlockModal({ selfLocked, file, onSubmit }) {
  const text = selfLocked ? "You have this file locked." : "This file is being edited by another user.";
  const subText = selfLocked
    ? 'By unlocking this file, your changes will not be synced up to Canopy. The unlocked files will be moved to the "Failed Uploads" folder on your desktop.'
    : `${file.locked_by_user_full_name} opened this file for editing ${DateTime.fromISO(file.locked_at, { zone: "utc" })
        .toLocal()
        .toRelative()}.
      If the file is unlocked, their changes will not be synced up to Canopy.`;

  return (
    <>
      <CpModal.Header />
      <CpModal.Body>
        <div className="cp-p-16">
          <CpEmptyState img="es_caution" text={text} subText={subText} />
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={() => onSubmit(true)} className="cp-mr-8">
          Unlock file
        </CpButton>
        <CpButton btnType="flat" onClick={() => onSubmit(false)}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </>
  );
}
