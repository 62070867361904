import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./basic-input.styles.css";
import { a } from "kremling";
import { noop } from "lodash";

BasicInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  rightText: PropTypes.string,
  shouldAutoFocus: PropTypes.bool,
  shouldAutoSelectText: PropTypes.bool,
  onEnterKey: PropTypes.func,
};

BasicInput.defaultProps = {
  placeholder: "",
  rightText: null,
  shouldAutoFocus: false,
  shouldAutoSelectText: false,
  onEnterKey: noop,
};

export default function BasicInput(props) {
  const inputRef = useRef(null);
  const [userHasInteracted, setUserHasInteracted] = useState(false);

  useFocusAndSelect();

  return (
    <div className={a("cps-form-group", styles.row)}>
      <div className={a(styles.left).m("cps-has-error", userHasInteracted && props.value && props.error)}>
        <label className={styles.label}>
          {props.label}
          <input
            ref={inputRef}
            className={a("cps-form-control", styles.input)}
            type="text"
            placeholder={props.placeholder}
            value={props.value}
            onChange={handleValueChange}
            disabled={props.isDisabled}
            onKeyDown={handleKeyDown}
          />
          <span className={a("cps-error-block", styles.error)}>{userHasInteracted && props.value && props.error}</span>
        </label>
      </div>
      {props.rightText && (
        <div className={styles.right}>
          <span>{props.rightText}</span>
        </div>
      )}
    </div>
  );

  function useFocusAndSelect() {
    useEffect(() => {
      if (inputRef && inputRef.current && (props.shouldAutoFocus || props.shouldAutoSelectText)) {
        inputRef.current.focus();

        if (props.shouldAutoSelectText) {
          inputRef.current.select();
        }
      }
      //eslint-disable-next-line
    }, [props.shouldAutoFocus, props.shouldAutoSelectText]);
  }

  function handleValueChange(e) {
    props.setValue(e.target.value);
    setUserHasInteracted(true);
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      props.onEnterKey();
    }
  }
}
