import React from "react";
import PropTypes from "prop-types";
import ContentExplorerHeader from "./content-explorer-header.component";
import ContentExplorerBody from "./content-explorer-body.component";
import ContentExplorerFooter from "./content-explorer-footer.component";

ContentExplorerPage.propTypes = {
  clientSelectEnabled: PropTypes.bool.isRequired,
  clientId: PropTypes.number,
  docId: PropTypes.string,
  saveHandler: PropTypes.func,
  path: PropTypes.array.isRequired,
  isSavingFile: PropTypes.bool.isRequired,
  folders: PropTypes.array,
  files: PropTypes.array,
};

export default function ContentExplorerPage(props) {
  return (
    <>
      <ContentExplorerHeader
        path={props.path}
        isSavingFile={props.isSavingFile}
        clientSelectEnabled={props.clientSelectEnabled}
      />
      <ContentExplorerBody
        isSavingFile={props.isSavingFile}
        folders={props.folders}
        files={props.files}
        clientId={props.clientId}
        path={props.path}
        clientSelectEnabled={props.clientSelectEnabled}
      />
      <ContentExplorerFooter
        docId={props.docId}
        saveHandler={props.saveHandler}
        isSavingFile={props.isSavingFile}
        clientId={props.clientId}
      />
    </>
  );
}
