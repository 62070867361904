// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-documents-save-to-canopy-files-dropdown-save-to-canopy-files-dropdown-styles__component--z1F2o svg {
  pointer-events: none;
}

.src-documents-save-to-canopy-files-dropdown-save-to-canopy-files-dropdown-styles__dropdown--K5Mwp {
  left: unset;
  right: 0;
  min-width: 31.0rem;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/documents/save-to-canopy-files-dropdown/save-to-canopy-files-dropdown.styles.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,QAAQ;EACR,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":[".component :global(svg) {\n  pointer-events: none;\n}\n\n.dropdown {\n  left: unset;\n  right: 0;\n  min-width: 31.0rem;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": `src-documents-save-to-canopy-files-dropdown-save-to-canopy-files-dropdown-styles__component--z1F2o`,
	"dropdown": `src-documents-save-to-canopy-files-dropdown-save-to-canopy-files-dropdown-styles__dropdown--K5Mwp`
};
export default ___CSS_LOADER_EXPORT___;
