import React, { useContext } from "react";
import PropTypes from "prop-types";
import { a } from "kremling";
import styles from "./content-explorer-header.styles.css";
import { CpButton } from "canopy-styleguide!sofe";
import SaveToCanopyFilesDropdownContext from "src/documents/save-to-canopy-files-dropdown/save-to-canopy-files-dropdown.context";

ContentExplorerHeader.propTypes = {
  path: PropTypes.array.isRequired,
  isSavingFile: PropTypes.bool.isRequired,
};

export default function ContentExplorerHeader(props) {
  const dispatch = useContext(SaveToCanopyFilesDropdownContext);
  const titleText = getTitleText();

  return (
    <div className={styles.header}>
      <div className={styles.left}>
        {props.clientSelectEnabled ? (
          <div className={a("cps-ellipsis", styles.title)}>Save file</div>
        ) : (
          <>
            {props.path.length > 1 && !props.isSavingFile && (
              <CpButton
                icon="arrow-line-left"
                aria-label="Go back to parent folder"
                onClick={() =>
                  dispatch({ type: "change_folder_id", payload: props.path[props.path.length - 2].file_id })
                }
              />
            )}
            <div className={a("cps-ellipsis", styles.title)}>{titleText}</div>
          </>
        )}
      </div>
      <div className={styles.right}>
        <CpButton icon="close-small" aria-label="Close dropdown" onClick={() => dispatch({ type: "close_dropdown" })} />
      </div>
    </div>
  );

  function getTitleText() {
    return props.path.length > 0 ? props.path[props.path.length - 1].name : "";
  }
}
