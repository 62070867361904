// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-body-styles__body--fOyHL {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.0rem 1.2rem 2.0rem 1.6rem;
}

.src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-body-styles__left--Wf79o {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-body-styles__right--tAjfY {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-body-styles__name--gmLTB {
  padding-left: 1.4rem;
}`, "",{"version":3,"sources":["webpack://./src/documents/save-to-canopy-files-dropdown/saved-confirmation-page/saved-confirmation-body.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".body {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 2.0rem 1.2rem 2.0rem 1.6rem;\n}\n\n.left {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.right {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.name {\n  padding-left: 1.4rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-body-styles__body--fOyHL`,
	"left": `src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-body-styles__left--Wf79o`,
	"right": `src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-body-styles__right--tAjfY`,
	"name": `src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-body-styles__name--gmLTB`
};
export default ___CSS_LOADER_EXPORT___;
