// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-documents-preview-pdf-page-styles__docsUiPreviewPage--wWBXj section {
  position: absolute;
  color: black;
  font-size: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/documents/preview/pdf-page.styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".docsUiPreviewPage section {\n  position: absolute;\n  color: black;\n  font-size: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"docsUiPreviewPage": `src-documents-preview-pdf-page-styles__docsUiPreviewPage--wWBXj`
};
export default ___CSS_LOADER_EXPORT___;
