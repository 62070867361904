import React, { useContext } from "react";
import PropTypes from "prop-types";
import { a } from "kremling";
import styles from "./saved-confirmation-header.styles.css";
import { CpButton, CpIcon } from "canopy-styleguide!sofe";
import SaveToCanopyFilesDropdownContext from "src/documents/save-to-canopy-files-dropdown/save-to-canopy-files-dropdown.context";
import { createLinkForFile } from "src/documents/documents.helpers";

SavedConfirmationHeader.propTypes = {
  path: PropTypes.array.isRequired,
  clientId: PropTypes.number.isRequired,
  selectedFile: PropTypes.object.isRequired,
};

export default function SavedConfirmationHeader(props) {
  const dispatch = useContext(SaveToCanopyFilesDropdownContext);
  const titleText = getTitleText();

  return (
    <div className={styles.header}>
      <div className={styles.left}>
        <CpIcon className={styles.checkmark} name="checkmark-circle-open-large" />
        <div className={a("cps-ellipsis", styles.title)}>
          <span>Saved in: </span>
          <a
            href={`${createLinkForFile([props.selectedFile.id], props.clientId)}&no_preview=true`}
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => dispatch({ type: "close_dropdown" })}
            title={titleText}
          >
            <span>{titleText}</span>
          </a>
        </div>
      </div>
      <div className={styles.right}>
        <CpButton icon="close-small" aria-label="Close dropdown" onClick={() => dispatch({ type: "close_dropdown" })} />
      </div>
    </div>
  );

  function getTitleText() {
    return props.path.length > 0 ? props.path[props.path.length - 1].name : "";
  }
}
