import React from "react";
import PropTypes from "prop-types";
import styles from "./rename-file-body.styles.css";
import BasicInput from "../basic-input/basic-input.component";

RenameFileBody.propTypes = {
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  isRenamingFile: PropTypes.bool.isRequired,
  setIsRenamingFile: PropTypes.func.isRequired,
  extension: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default function RenameFileBody(props) {
  return (
    <div className={styles.body}>
      <BasicInput
        label="New name"
        value={props.name}
        setValue={props.setName}
        isDisabled={props.isRenamingFile}
        error={props.error}
        placeholder="Enter new name..."
        rightText={props.extension}
        shouldAutoFocus={true}
        shouldAutoSelectText={true}
        onEnterKey={() => props.setIsRenamingFile(true)}
      />
    </div>
  );
}
