import React from "react";
import ReactDOMClient from "react-dom/client";
import { useCss, k } from "kremling";
import { CpButton, CpIcon, CpLoader } from "canopy-styleguide!sofe";

export default function VirusModal(props) {
  function renderVirusScanningContent() {
    return (
      <>
        <div className="cps-card__header cps-subheader-sm virus-header">
          This document is being scanned for viruses
          <CpLoader size="sm" />
          <CpButton
            onClick={props.handleCloseModal}
            icon="close-large"
            aria-label="Close modal"
            className="close-modal"
          />
        </div>
        <div className="cps-card__body">
          <p>
            While this document is being scanned exporting is unavailable. This process may take a few minutes, please
            check back later to export your file.
          </p>
        </div>
        <div className="cps-card__footer">
          <CpButton onClick={props.handleCloseModal} btnType="primary">
            Close
          </CpButton>
        </div>
      </>
    );
  }

  function renderVirusFoundContent() {
    return (
      <>
        <div className="cps-card__header cps-subheader-sm virus-header">
          <CpIcon
            className="cps-margin-right-8"
            style={{ color: "var(--cps-color-mandy)" }}
            name="alert-triangle-open-large"
          />
          This document may be infected
          <CpButton
            onClick={props.handleCloseModal}
            icon="close-large"
            aria-label="Close modal"
            className="close-modal"
          />
        </div>
        <div className="cps-card__body">
          <p>
            Our antivirus scan has found a virus associated with this file. For your safety, this file is in view-only
            mode.
          </p>
        </div>
        <div className="cps-card__footer">
          <CpButton onClick={props.handleCloseModal} btnType="primary">
            Close
          </CpButton>
        </div>
      </>
    );
  }

  function renderContent() {
    if (props.virusFound) {
      return renderVirusFoundContent();
    } else {
      return renderVirusScanningContent();
    }
  }

  const styles = useCss(css);

  return ReactDOMClient.createPortal(
    <div className="cps-modal" role="dialog" {...styles}>
      <div className={`cps-modal__screen`}>
        <div className="cps-modal__dialog cps-card cps-card__height-3">{renderContent()}</div>
      </div>
    </div>,
    document.getElementById("cps-app")
  );
}

const css = k`
  .virus-header {
    display: flex;
    align-items: center;
  }
  .close-modal {
    margin-left: auto;
  }
  .loader {
    padding: 0 24px;
  }
  .cps-modal {
    position: relative;
    z-index: 100020 !important;
  }
`;
