import React, { useContext } from "react";
import styles from "./rename-file-header.styles.css";
import { CpButton } from "canopy-styleguide!sofe";
import SaveToCanopyFilesDropdownContext from "src/documents/save-to-canopy-files-dropdown/save-to-canopy-files-dropdown.context";

export default function RenameFileHeader(props) {
  const dispatch = useContext(SaveToCanopyFilesDropdownContext);

  return (
    <div className={styles.header}>
      <div className={styles.left}>
        <span className={styles.title}>Rename</span>
      </div>
      <div className={styles.right}>
        <CpButton icon="close-small" aria-label="Close dropdown" onClick={() => dispatch({ type: "close_dropdown" })} />
      </div>
    </div>
  );
}
