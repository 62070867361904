// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-documents-save-to-canopy-files-dropdown-content-explorer-page-content-explorer-footer-styles__footer--W3U_f {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6.4rem;
  padding: 0 1.6rem;
  border-top: 0.1rem solid var(--cps-color-silver);
}

.src-documents-save-to-canopy-files-dropdown-content-explorer-page-content-explorer-footer-styles__left--sJYX8 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.src-documents-save-to-canopy-files-dropdown-content-explorer-page-content-explorer-footer-styles__right--WtANp {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.src-documents-save-to-canopy-files-dropdown-content-explorer-page-content-explorer-footer-styles__saveButton--H_Uo3 {
  width: 15rem;
}
`, "",{"version":3,"sources":["webpack://./src/documents/save-to-canopy-files-dropdown/content-explorer-page/content-explorer-footer.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB,gDAAgD;AAClD;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".footer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 6.4rem;\n  padding: 0 1.6rem;\n  border-top: 0.1rem solid var(--cps-color-silver);\n}\n\n.left {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.right {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.saveButton {\n  width: 15rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `src-documents-save-to-canopy-files-dropdown-content-explorer-page-content-explorer-footer-styles__footer--W3U_f`,
	"left": `src-documents-save-to-canopy-files-dropdown-content-explorer-page-content-explorer-footer-styles__left--sJYX8`,
	"right": `src-documents-save-to-canopy-files-dropdown-content-explorer-page-content-explorer-footer-styles__right--WtANp`,
	"saveButton": `src-documents-save-to-canopy-files-dropdown-content-explorer-page-content-explorer-footer-styles__saveButton--H_Uo3`
};
export default ___CSS_LOADER_EXPORT___;
