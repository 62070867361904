import React from "react";
import { CpModal, CpEmptyState, CpButton } from "canopy-styleguide!sofe";
import { k, useCss } from "kremling";

export default function DesktopAssistantOffModal({ onSubmit }) {
  const scope = useCss(css);
  return (
    <>
      <CpModal.Header />
      <CpModal.Body>
        <div {...scope} className="cp-p-16">
          <CpEmptyState
            img="es_new_product"
            text="You're almost ready to edit files!"
            subText="Can't connect to Canopy Desktop Assistant. Please make sure the app is running to start your edit."
          />
          <div className="download cps-caption">
            <span>Don't have Canopy Desktop Assistant installed?</span>
            &nbsp;
            <a href="https://support.getcanopy.com/hc/en-us/articles/9131174507419-Install-the-Desktop-Assistant">
              Download it here.
            </a>
          </div>
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={onSubmit} className="cp-mr-8">
          Okay
        </CpButton>
        <CpButton
          anchor
          btnType="flat"
          href="https://support.getcanopy.com/hc/en-us/articles/9131201684891-Set-Up-Your-Desktop-Assistant-Scanner"
          target="_blank"
        >
          Learn more
        </CpButton>
      </CpModal.Footer>
    </>
  );
}

const css = k`
  .download {
    text-align: center;
    margin-top: 16px;
    color: var(--cp-color-app-secondary-text);
  }
`;
