import { fetchAsObservable } from "fetcher!sofe";
import { defer, from, of } from "rxjs";
import { bufferCount, catchError, concatMap, flatMap, map, pluck, retryWhen, delay } from "rxjs/operators";
import { partial } from "lodash";
import { createLinkForFile, createLinkForFileClients } from "./documents.helpers";
import { FILE_SIZE_LIMIT, FILTER_PREFIXES } from "src/common/common.constants";
import { handleError } from "../handle-error.helper";

export function getFolders(clientId) {
  // GET /api/docs/clients/:id/folders
  return fetchAsObservable(`/api/docs/clients/${clientId}/folders/0/folders`).pipe(map((resp) => resp.folders));
}

export function createFolder(clientId, folderName, parentFolderId = "0", permissions) {
  const folder = {
    name: folderName,
    parent_folder_id: parentFolderId,
    permissions,
  };

  return fetchAsObservable(`/api/docs/clients/${clientId}/folders`, {
    method: "POST",
    body: { folder },
  }).pipe(map((resp) => resp.files[0]));
}

export function combineFiles(files, archive, combinedFileName, parentFolderId, isAi = false) {
  return fetchAsObservable(`/api/docs/files:combine`, {
    method: "POST",
    body: {
      data: {
        archive_source_files: archive,
        file_references: files.map((file) => ({
          location_type: "file_id",
          value: file.id,
        })),
        new_file_metadata: {
          name: combinedFileName,
          parent_folder_id: parentFolderId,
        },
        include_annotations: true,
        smart_combine: isAi ? true : false,
      },
    },
  }).toPromise();
}

// Update folders or files, or a mix
// docIds can be a string or an array
export function updateDocs(clientId, docIds, docData) {
  // PATCH /api/docs/clients/:id/files
  return fetchAsObservable(`/api/docs/clients/${clientId}/files`, {
    method: "PATCH",
    body: { file: docData, file_ids: docIds },
  }).pipe(map((resp) => resp.files));
}

// Delete folders or files, or a mix
// docIds can be a string or an array
export function deleteDocs(clientId, docIds) {
  // DELETE /api/docs/clients/:id/files/:id[,:id,:id...]
  if (docIds instanceof Array) docIds = docIds.join(",");

  return fetchAsObservable(`/api/docs/clients/${clientId}/files/${docIds}`, {
    method: "DELETE",
  }).pipe(map((resp) => resp.files));
}

// Default is 0 for root-level
export function getFilesInFolder(clientId, folderId = "0") {
  // GET /api/docs/clients/:id/folders/:id/files
  return fetchAsObservable(`/api/docs/clients/${clientId}/folders/${folderId}/files`).pipe(
    map((resp) => resp.folder_files)
  );
}

export function getFoldersInFolder(clientId, folderId) {
  // GET /api/docs/clients/:id/folders/:id/folders
  return fetchAsObservable(`/api/docs/clients/${clientId}/folders/${folderId}/folders`).pipe(
    map((resp) => resp.folders)
  );
}

export function getTrash(clientId) {
  // GET /api/docs/clients/:id/trash_files
  return fetchAsObservable(`/api/docs/clients/${clientId}/trash_files`).pipe(map((resp) => resp.folder_files));
}

export function searchTrash(clientId, term) {
  // GET /api/docs/clients/:id/trash/files?name_contains=
  return fetchAsObservable(`/api/docs/clients/${clientId}/trash/files?name_contains=${term}`).pipe(
    map((resp) => resp.folder_files)
  );
}

export function getFiles(fileIds, printOrDownload) {
  return fetchAsObservable(
    `/api/docs/search?ids=${fileIds}${printOrDownload ? `&purpose=${printOrDownload}` : ""}`
  ).pipe(pluck("files"));
}

export function getFile(fileId, includePreview, printOrDownload) {
  // GET /api/docs/clients/:id/files/:id[,:id,:id,...]
  let url = `/api/docs/files/${fileId}`;
  if (includePreview) {
    url += `?includes=preview_links`;
  }

  if (printOrDownload) {
    url += includePreview ? "&" : "?";
    url += `purpose=${printOrDownload}`;
  }

  return fetchAsObservable(url).pipe(pluck("file"));
}

export function getFileMeta(clientId, fileId, includePreview) {
  // GET /api/docs/clients/:id/files/:id[,:id,:id,...]
  return fetchAsObservable(
    `/api/docs/clients/${clientId}/files/${fileId}${includePreview ? "?includes=preview_links" : ""}`
  ).pipe(map((resp) => resp.files[0]));
}

export function getFilesMeta(clientId, file_ids) {
  // POST /api/docs/clients/{client_id}/files/files-of-ids
  return fetchAsObservable(`/api/docs/clients/${clientId}/files/files-of-ids`, {
    method: "POST",
    body: { file_ids },
  }).pipe(pluck("files"));
}

export function getClientNewFiles(clientId) {
  return fetchAsObservable(`/api/docs/clients/${clientId}/files-not-seen`).pipe(map((resp) => resp.files));
}

export function markFileAsSeen(fileId) {
  return fetchAsObservable(`/api/docs/files/${fileId}:mark-seen`, { method: "POST" });
}

// deprecated
export function searchFiles(clientId, term) {
  // GET /api/docs/clients/:id/files?name_contains=
  return fetchAsObservable(`/api/docs/clients/${clientId}/files?name_contains=${term}`).pipe(pluck("folder_files"));
}

// Get the url for uploading to s3
export function getUploadUrl(clientId) {
  return fetchAsObservable(`/api/docs/clients/${clientId}/s3`).pipe(map((resp) => resp.s3));
}

export function uploadFiles(clientId, parentFolderId, url, otherFields, files) {
  return from(files).pipe(flatMap(partial(toUploadObservable, clientId, parentFolderId, url, otherFields)));
}

function toUploadObservable(clientId, parentFolderId, url, otherFields, file) {
  if (file.invalidFile) return of(file);
  // For s3 we change the file name to include the uuid
  const s3FileName = file.uuid + "/" + file.filename;
  const dbFile = {
    name: file.filename,
    type: file.file.type,
    size: file.file.size,
  };
  const s3File = file.file;
  const s3Path = getS3Path(otherFields.key, s3FileName);
  const uploadFields = {
    ...otherFields,
    key: s3Path,
  };

  // s3 needs the body in form data
  const formData = new FormData();
  for (let field in uploadFields) formData.append(field, uploadFields[field]);
  formData.append("file", s3File);
  const uploadStream = fetchAsObservable(url, {
    method: "POST",
    credentials: "omit",
    body: formData,
  }).pipe(
    catchError((ex) => {
      handleError(ex);
      return of("s3error");
    }),
    flatMap(partial(toStoreObservable, clientId, parentFolderId, dbFile, s3Path))
  );
  return uploadStream;
}

export function filterToFiles(item) {
  return new Promise((resolve) => {
    // The majority of this function is fancy stuff to allow for reading large file

    //This is how much of a file to read at a time
    const CHUNK_SIZE = 1024 * 64;
    //This tracks how much of file we've read
    let offset = 0;

    const filereader = new FileReader();

    if (item.file.size > FILE_SIZE_LIMIT) {
      resolve({ invalidFile: true, file: item.file, uuid: item.uuid });
    }

    filereader.onload = () => {
      //This runs once we've finished reading a chunk
      offset += CHUNK_SIZE;
      readFileSlice();
    };

    filereader.onerror = (err) => {
      resolve({ invalidFile: true, file: item.file, uuid: item.uuid });
    };

    readFileSlice();

    function readFileSlice() {
      if (offset >= item.file.size) {
        return resolve(item);
      }

      //The slice is our chunk of the file to read
      const slice = item.file.slice(offset, offset + CHUNK_SIZE);
      filereader.readAsArrayBuffer(slice);
    }
  });
}

export function filesUploadObservable(arr, clientId, parentFolderId) {
  return from(arr).pipe(
    bufferCount(8),
    concatMap((chunk) => {
      return defer(() => {
        return new Promise((resolve, reject) => {
          getUploadUrl(clientId).subscribe(
            (s3info) => {
              resolve({ s3info, chunk });
            },
            (err) => {
              reject(new Error(`Failed to get s3 upload info for client ${clientId}. Err: ${JSON.stringify(err)}`));
            }
          );
        });
      }).pipe(
        concatMap(({ chunk, s3info }) => {
          return from(chunk).pipe(
            concatMap((item) => {
              return filterToFiles(item);
            }),
            concatMap((item) => {
              return toUploadObservable(clientId, parentFolderId, s3info.url, s3info.fields, item);
            })
          );
        })
      );
    })
  );
}

export function getS3Path(key, filename) {
  return key.replace("${filename}", filename);
}

export function createS3FileName(file) {
  return file.uuid + file.filename;
}

function toStoreObservable(clientId, parentFolderId, file, pathToS3, s3Resp) {
  const fileData = {
    name: file.name,
    parent_folder_id: parentFolderId == 0 ? `${FILTER_PREFIXES.CLIENTS}${clientId}` : parentFolderId,
    path_to_s3: pathToS3,
    description: "",
    mimetype: file.type,
    is_visible: false,
    filesize: file.size || 0,
  };
  if (s3Resp === "s3error") return of({ ...fileData, s3error: true });
  return addFile(clientId, fileData);
}

// Adds a file to the db after upload
export function addFile(clientId, fileData) {
  if (!fileData.type) {
    fileData = { ...fileData, type: "other" };
  }

  return fetchAsObservable(`/api/docs/folders/${fileData.parent_folder_id}/files`, {
    method: "POST",
    body: { file: fileData },
  }).pipe(
    map((resp) => resp.file),
    catchError((error) => {
      throw new Error(
        `Problem storing uploaded file, ${JSON.stringify(
          fileData
        )} for client ${clientId}. This file is stored now in s3, but not in DDG. Err ${JSON.stringify(error)}`
      );
    })
  );
}

export function getClientList(clientId) {
  return fetchAsObservable(`/api/docs/clients/${clientId}/folders/0/files?view=client`).pipe(
    map((resp) => resp.folder_files)
  );
}

export function getClientFolderList(clientId) {
  return fetchAsObservable(`/api/docs/clients/${clientId}/folders/0/folders?view=client`).pipe(
    map((resp) => resp.folders)
  );
}

export function getClientFilesInFolder(clientId, folderId = "0") {
  // GET /api/docs/clients/:id/folders/:id/files?view=client
  return fetchAsObservable(`/api/docs/clients/${clientId}/folders/${folderId}/files?view=client`).pipe(
    map((resp) => resp.folder_files)
  );
}

export function unzipFile(clientId, docId) {
  return fetchAsObservable(`/api/docs/clients/${clientId}/files/${docId}`, {
    method: "PATCH",
    body: { file: { extract: true } },
  }).pipe(
    flatMap(() => {
      return fetchAsObservable(`/api/docs/clients/${clientId}/files/${docId}`).pipe(
        map((resp) => {
          const zipResponse = resp.files[0];
          if (zipResponse.zip_extract_status.status === "complete") {
            return resp;
          } else if (zipResponse.zip_extract_status.status === "in-progress") {
            throw new Error("retry");
          } else if (zipResponse.zip_extract_status.status === "error") {
            return new Error("error");
          }
        })
      );
    }),
    delay(2000),
    retryWhen((error) => {
      return error;
    }),
    map((response) => {
      if (response instanceof Error) throw response;
      else return response;
    })
  );
}

export function zipFiles(clientId, fileIds) {
  return fetchAsObservable(`/api/docs/clients/${clientId}/files/${fileIds.join(",")}/zip`).pipe(
    map((resp) => resp.zip[0])
  );
}

export function emailShareFiles(senderId, clientId, files, message, recipients) {
  const fileIds = files.map((f) => f.id);

  const recipientsBody = recipients.map((recipient) => {
    let link = createLinkForFile(fileIds, clientId);
    if (recipient.role === "Client") {
      link = createLinkForFileClients(fileIds, clientId);
    }

    const payload = {
      id: recipient.id,
      mergeTags: {
        SENDER_DS_ID: senderId,
        body: message,
        URL: link,
      },
    };

    if (files.length > 1) {
      payload.mergeTags.FILE_NAMES = files.map((f) => f.name);
    } else {
      payload.mergeTags.FILE_NAME = files[0].name;
    }

    return payload;
  });

  const body = {
    notifications: {
      correlationId: "26ec72w4563456g345625",
      templateName: "attachment-shared",
      tag: "attachment-shared",
      recipients: recipientsBody,
    },
  };

  return fetchAsObservable(`/wg/notifications`, {
    method: "POST",
    body,
  });
}

export function getDocument(docId) {
  return fetchAsObservable(`/api/docs/files/${docId}?includes=preview_links`);
}

export function getDocumentPreview(docId) {
  return getDocument(docId).pipe(
    pluck("file"),
    map((file) => ({
      title: file.name,
      pages: file.preview_links,
      type: file.type || file.mimetype,
    }))
  );
}
