import React, { useContext } from "react";
import PropTypes from "prop-types";
import styles from "./content-explorer-footer.styles.css";
import { CpButton, CpLoader } from "canopy-styleguide!sofe";
import SaveToCanopyFilesDropdownContext from "src/documents/save-to-canopy-files-dropdown/save-to-canopy-files-dropdown.context";

ContentExplorerFooter.propTypes = {
  docId: PropTypes.string,
  saveHandler: PropTypes.func,
  isSavingFile: PropTypes.bool.isRequired,
};

export default function ContentExplorerFooter(props) {
  const dispatch = useContext(SaveToCanopyFilesDropdownContext);

  return (
    <div className={styles.footer}>
      <div className={styles.left}>
        <CpButton
          btnType="primary"
          disabled={(!props.docId && !props.saveHandler) || !props.clientId}
          onClick={() => {
            if (!props.isSavingFile) {
              dispatch({ type: "save_file" });
            }
          }}
          showLoader={props.isSavingFile}
          className={styles.saveButton}
        >
          {!props.isSavingFile && `Save here`}
        </CpButton>
      </div>
      <div className={styles.right}>
        {!props.isSavingFile && !!props.clientId && (
          <CpButton icon="folder-add" aria-label="Create folder" onClick={() => dispatch({ type: "create_folder" })} />
        )}
      </div>
    </div>
  );
}
