import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import SaveToCanopyFilesDropdownContext from "src/documents/save-to-canopy-files-dropdown/save-to-canopy-files-dropdown.context";
import RenameFileHeader from "./rename-file-header.component";
import RenameFileBody from "./rename-file-body.component";
import RenameFileFooter from "./rename-file-footer.component";
import { patchFileName } from "src/global-files/resources/files.resource";
import { noExt, getExt } from "src/documents/documents.helpers";
import { handleError } from "src/handle-error.helper";
import { finalize } from "rxjs/operators";
import { track } from "cp-analytics";

RenameFilePage.propTypes = {
  selectedFile: PropTypes.object.isRequired,
  files: PropTypes.array,
  fileOrigin: PropTypes.string,
};

export default function RenameFilePage(props) {
  const dispatch = useContext(SaveToCanopyFilesDropdownContext);
  const [name, setName] = useState(() => noExt(props.selectedFile.name));
  const [extension] = useState(() => (getExt(props.selectedFile.name) ? `.${getExt(props.selectedFile.name)}` : ""));
  const [isRenamingFile, setIsRenamingFile] = useState(false);

  const trimmedNameWithExtension = `${name.trim()}${extension}`;

  const error = errorCheck(trimmedNameWithExtension, extension, props.files);

  useEffect(() => {
    if (isRenamingFile) {
      if (error) {
        setIsRenamingFile(false);
      } else {
        const subscription = patchFileName(props.selectedFile.id, trimmedNameWithExtension)
          .pipe(
            finalize(() => {
              setIsRenamingFile(false);
            })
          )
          .subscribe((response) => {
            trackRename(props.fileOrigin, response.file.mimetype);
            dispatch({ type: "rename_file_completed", payload: response.file });
          }, handleError);

        return () => subscription && subscription.unsubscribe();
      }
    }
  }, [isRenamingFile, error, props.selectedFile, props.fileOrigin, trimmedNameWithExtension, dispatch]);

  return (
    <>
      <RenameFileHeader />
      <RenameFileBody
        name={name}
        setName={setName}
        isRenamingFile={isRenamingFile}
        setIsRenamingFile={setIsRenamingFile}
        extension={extension}
        error={error}
      />
      <RenameFileFooter isRenamingFile={isRenamingFile} setIsRenamingFile={setIsRenamingFile} error={error} />
    </>
  );
}

function errorCheck(trimmedNameWithExtension, extension, files) {
  let error = null;

  if (trimmedNameWithExtension === extension) {
    error = `File name cannot be blank!`;
  } else if (files && files.some((file) => file.name === trimmedNameWithExtension)) {
    error = `File name already exists!`;
  }

  return error;
}

function trackRename(fileOrigin, fileType) {
  if (fileOrigin && fileType && typeof fileOrigin === "string" && typeof fileType === "string") {
    track("practice_management", "files", "save_to_canopy_file_renamed", {
      file_origin: fileOrigin,
      file_type: fileType,
    });
  }
}
