// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-documents-save-to-canopy-files-dropdown-basic-input-basic-input-styles__row--HsXWx {
  display: flex;
  justify-content: space-between;
}

.src-documents-save-to-canopy-files-dropdown-basic-input-basic-input-styles__left--gnx_C {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.src-documents-save-to-canopy-files-dropdown-basic-input-basic-input-styles__right--Vafr7 {
  padding-left: 0.8rem;
  padding-top: 3.0rem;
}

.cps-form-group .src-documents-save-to-canopy-files-dropdown-basic-input-basic-input-styles__label--DQO7E {
  width: 100%;
}

.src-documents-save-to-canopy-files-dropdown-basic-input-basic-input-styles__input--Ovc9Y {
  margin-top: 0.4rem;
}

.src-documents-save-to-canopy-files-dropdown-basic-input-basic-input-styles__error--rRFXT {
  font-size: 1.4rem;
}`, "",{"version":3,"sources":["webpack://./src/documents/save-to-canopy-files-dropdown/basic-input/basic-input.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".row {\n  display: flex;\n  justify-content: space-between;\n}\n\n.left {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n}\n\n.right {\n  padding-left: 0.8rem;\n  padding-top: 3.0rem;\n}\n\n:global(.cps-form-group) .label {\n  width: 100%;\n}\n\n.input {\n  margin-top: 0.4rem;\n}\n\n.error {\n  font-size: 1.4rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `src-documents-save-to-canopy-files-dropdown-basic-input-basic-input-styles__row--HsXWx`,
	"left": `src-documents-save-to-canopy-files-dropdown-basic-input-basic-input-styles__left--gnx_C`,
	"right": `src-documents-save-to-canopy-files-dropdown-basic-input-basic-input-styles__right--Vafr7`,
	"label": `src-documents-save-to-canopy-files-dropdown-basic-input-basic-input-styles__label--DQO7E`,
	"input": `src-documents-save-to-canopy-files-dropdown-basic-input-basic-input-styles__input--Ovc9Y`,
	"error": `src-documents-save-to-canopy-files-dropdown-basic-input-basic-input-styles__error--rRFXT`
};
export default ___CSS_LOADER_EXPORT___;
