import React from "react";
import PropTypes from "prop-types";
import styles from "./create-folder-body.styles.css";
import BasicInput from "../basic-input/basic-input.component";

CreateFolderBody.propTypes = {
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  isCreatingFolder: PropTypes.bool.isRequired,
  setIsCreatingFolder: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default function CreateFolderBody(props) {
  return (
    <div className={styles.body}>
      <BasicInput
        label="Folder name"
        value={props.name}
        setValue={props.setName}
        isDisabled={props.isCreatingFolder}
        error={props.error}
        placeholder="Enter folder name..."
        shouldAutoFocus={true}
        onEnterKey={() => props.setIsCreatingFolder(true)}
      />
    </div>
  );
}
