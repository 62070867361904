// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-header-styles__header--K_AEE {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.8rem;
  padding: 0 0.8rem 0 1.6rem;
  border-bottom: 0.1rem solid var(--cps-color-silver);
}

.src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-header-styles__left--sihcO {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-header-styles__right--iBYc6 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-header-styles__checkmark--ML_0I {
  color: var(--cps-color-primary);
}

.src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-header-styles__title--SE_eI {
  width: 22.0rem;
  padding-left: 1.2rem;
  font-size: 1.6rem;
  color: var(--cps-color-cool-gray);
}`, "",{"version":3,"sources":["webpack://./src/documents/save-to-canopy-files-dropdown/saved-confirmation-page/saved-confirmation-header.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,0BAA0B;EAC1B,mDAAmD;AACrD;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,cAAc;EACd,oBAAoB;EACpB,iBAAiB;EACjB,iCAAiC;AACnC","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 4.8rem;\n  padding: 0 0.8rem 0 1.6rem;\n  border-bottom: 0.1rem solid var(--cps-color-silver);\n}\n\n.left {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.right {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.checkmark {\n  color: var(--cps-color-primary);\n}\n\n.title {\n  width: 22.0rem;\n  padding-left: 1.2rem;\n  font-size: 1.6rem;\n  color: var(--cps-color-cool-gray);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-header-styles__header--K_AEE`,
	"left": `src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-header-styles__left--sihcO`,
	"right": `src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-header-styles__right--iBYc6`,
	"checkmark": `src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-header-styles__checkmark--ML_0I`,
	"title": `src-documents-save-to-canopy-files-dropdown-saved-confirmation-page-saved-confirmation-header-styles__title--SE_eI`
};
export default ___CSS_LOADER_EXPORT___;
