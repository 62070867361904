import { get, isEmpty } from "lodash";

export { v4 as uuid } from "uuid";

export function setAt(collection, at, set) {
  const atArr = at.split(".");
  const index = atArr.pop();
  const setLocation = !isEmpty(atArr) ? get(collection, atArr) : collection;
  setLocation[index] = {
    ...get(collection, at),
    ...set,
  };
  return collection;
}

export function getHeaderHeight(showingDeletedView, showingSubheader, searching) {
  const headerHeight = 65;
  const subheaderHeight = 40;
  const searchBarHeight = 40;
  const deletedBannerHeight = 35;

  return (
    headerHeight +
    (showingDeletedView ? deletedBannerHeight : 0) +
    (showingSubheader ? subheaderHeight : 0) +
    ((showingDeletedView && showingSubheader) || showingSubheader || searching ? searchBarHeight : 0)
  );
}

export function fileSizer(size) {
  if (size < 1000) return `${size} B`;
  if (size < 1000000) return `${(size / 1000).toFixed(2)} KB`;
  if (size < 1000000000) return `${(size / 1000000).toFixed(2)} MB`;
  if (size < 1000000000000) return `${(size / 1000000000).toFixed(2)} GB`;
}
