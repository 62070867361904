import React from "react";
import { CpModal, CpEmptyState, CpButton } from "canopy-styleguide!sofe";

export default function DesktopAssistantErrorModal({ onSubmit }) {
  return (
    <>
      <CpModal.Header />
      <CpModal.Body>
        <div className="cp-p-16">
          <CpEmptyState
            img="es_caution"
            text="Something went wrong connecting to Canopy Desktop Assistant"
            subText="Please be sure you've updated to the latest version of the Canopy Desktop Assistant application."
          />
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={onSubmit} className="cp-mr-8">
          Okay
        </CpButton>
      </CpModal.Footer>
    </>
  );
}
