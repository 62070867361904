import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import { ErrorBoundary } from "error-logging!sofe";
import SaveToCanopyFilesDropdown from "src/documents/save-to-canopy-files-dropdown/save-to-canopy-files-dropdown.component";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: ErrorBoundary({ featureName: "docs-ui_save-to-canopy-files-dropdown_parcel" })(
    SaveToCanopyFilesDropdown
  ),
});

const featureToggles = [];

const bootstrap = [
  () => {
    if (featureToggles.length) {
      return SystemJS.import("feature-toggles!sofe").then((ft) => ft.fetchFeatureToggles(...featureToggles));
    } else {
      return Promise.resolve();
    }
  },
  reactLifecycles.bootstrap,
];

export const SaveToCanopyFilesDropdownParcel = {
  ...reactLifecycles,
  bootstrap,
};
