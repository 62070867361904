import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import { ErrorBoundary } from "error-logging!sofe";

import VirusModal from "./virus-modal.component";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: ErrorBoundary({ featureName: "Docs Virus Scanning" })(VirusModal),
});

const featureToggles = [];

const bootstrap = [
  () => {
    if (featureToggles.length) {
      return SystemJS.import("feature-toggles!sofe").then((ft) => ft.fetchFeatureToggles(...featureToggles));
    } else {
      return Promise.resolve();
    }
  },
  reactLifecycles.bootstrap,
];

export const VirusModalParcel = {
  ...reactLifecycles,
  bootstrap,
};
