export const refreshContentTypes = {
  SOFT: "soft",
  HARD: "hard",
};

export const initialState = {
  isOpen: false,
  path: [],
  rootFolderId: null,
  folderId: null,
  folders: null,
  files: null,
  refreshContent: null,
  page: "contentExplorer",
  selectedFile: null,
  isSavingFile: false,
  savedFolderId: null,
  savedDocId: null,
  clientId: null,
};

export function reducer(state, action) {
  switch (action.type) {
    case "change_client_id": {
      const rootFolderId = `CON${action.payload}`;

      return {
        ...initialState,
        isOpen: state.isOpen,
        rootFolderId: rootFolderId,
        folderId: rootFolderId,
        clientId: action.payload,
      };
    }
    case "change_folder_id":
      return {
        ...state,
        folderId: action.payload,
        refreshContent: refreshContentTypes.HARD,
      };
    case "set_folder_contents":
      return {
        ...state,
        folders: action.payload.folders,
        files: action.payload.files,
        path: action.payload.path,
      };
    case "contents_refreshed":
      return {
        ...state,
        refreshContent: null,
      };
    case "save_file":
      return {
        ...state,
        isSavingFile: true,
        savedFolderId: state.folderId,
      };
    case "save_file_completed": {
      return {
        ...state,
        selectedFile: action.payload,
        isSavingFile: false,
        savedDocId: action.payload.id,
        refreshContent: refreshContentTypes.SOFT,
        page: "savedConfirmation",
      };
    }
    case "save_file_errored": {
      return {
        ...state,
        isSavingFile: false,
        savedFolderId: null,
      };
    }
    case "create_folder": {
      return {
        ...state,
        refreshContent: refreshContentTypes.SOFT,
        page: "createFolder",
      };
    }
    case "create_folder_completed": {
      return {
        ...state,
        refreshContent: refreshContentTypes.SOFT,
        page: "contentExplorer",
      };
    }
    case "create_folder_cancelled": {
      return {
        ...state,
        refreshContent: refreshContentTypes.SOFT,
        page: "contentExplorer",
      };
    }
    case "rename_file": {
      return {
        ...state,
        selectedFile: action.payload,
        refreshContent: refreshContentTypes.SOFT,
        page: "renameFile",
      };
    }
    case "rename_file_completed": {
      return {
        ...state,
        selectedFile: action.payload,
        refreshContent: refreshContentTypes.SOFT,
        page: "savedConfirmation",
      };
    }
    case "rename_file_cancelled": {
      const defaultRenameCancelState = {
        ...state,
        refreshContent: refreshContentTypes.SOFT,
      };

      if (state.savedDocId) {
        return {
          ...defaultRenameCancelState,
          page: "savedConfirmation",
        };
      } else {
        return {
          ...defaultRenameCancelState,
          selectedFile: null,
          page: "contentExplorer",
        };
      }
    }
    case "open_dropdown": {
      const defaultOpenState = {
        ...state,
        isOpen: true,
      };

      if (state.isSavingFile) {
        return {
          ...defaultOpenState,
          refreshContent: refreshContentTypes.SOFT,
        };
      } else if (state.savedDocId) {
        return {
          ...defaultOpenState,
        };
      } else {
        return {
          ...defaultOpenState,
          refreshContent: refreshContentTypes.HARD,
        };
      }
    }
    case "close_dropdown": {
      const defaultCloseState = {
        ...initialState,
        rootFolderId: state.rootFolderId,
        isSavingFile: state.isSavingFile,
        savedFolderId: state.savedFolderId,
        clientId: state.clientId,
      };

      if (state.isSavingFile) {
        return {
          ...defaultCloseState,
          path: state.path,
          folderId: state.savedFolderId,
          selectedFile: state.selectedFile,
          savedDocId: state.savedDocId,
          page: "contentExplorer",
        };
      } else if (state.savedDocId) {
        return {
          ...defaultCloseState,
          path: state.path,
          folderId: state.savedFolderId,
          selectedFile: state.selectedFile,
          savedDocId: state.savedDocId,
          page: "savedConfirmation",
        };
      } else {
        return {
          ...defaultCloseState,
          folderId: state.rootFolderId,
        };
      }
    }
    default:
      return state;
  }
}
