import React from "react";
import { CpModal, CpEmptyState, CpButton } from "canopy-styleguide!sofe";

export default function DesktopAssistantSignedOut({ onSubmit }) {
  return (
    <>
      <CpModal.Header />
      <CpModal.Body>
        <div className="cp-p-16">
          <CpEmptyState
            img="es_login"
            text="You're almost ready to edit files!"
            subText="You'll need to sign in to Canopy Desktop Assistant to start your edit."
          />
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" className="cp-mr-8" onClick={() => onSubmit("focusDA")}>
          Go to sign in
        </CpButton>
        <CpButton
          anchor
          btnType="flat"
          href="https://support.getcanopy.com/hc/en-us/articles/9131201684891-Set-Up-Your-Desktop-Assistant-Scanner"
          target="_blank"
        >
          Learn more
        </CpButton>
      </CpModal.Footer>
    </>
  );
}
