import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import SaveToCanopyFilesDropdownContext from "src/documents/save-to-canopy-files-dropdown/save-to-canopy-files-dropdown.context";
import CreateFolderHeader from "./create-folder-header.component";
import CreateFolderBody from "./create-folder-body.component";
import CreateFolderFooter from "./create-folder-footer.component";
import { createFolder } from "src/documents/documents.resource";
import { handleError } from "src/handle-error.helper";
import { finalize } from "rxjs/operators";

CreateFolderPage.propTypes = {
  clientId: PropTypes.number.isRequired,
  folderId: PropTypes.string.isRequired,
  folders: PropTypes.array,
};

export default function CreateFolderPage(props) {
  const dispatch = useContext(SaveToCanopyFilesDropdownContext);
  const [name, setName] = useState("");
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);

  const trimmedName = name.trim();
  const error = errorCheck(trimmedName, props.folders);

  useEffect(() => {
    if (isCreatingFolder) {
      if (error) {
        setIsCreatingFolder(false);
      } else {
        const subscription = createFolder(props.clientId, trimmedName, props.folderId)
          .pipe(
            finalize(() => {
              setIsCreatingFolder(false);
            })
          )
          .subscribe(() => {
            dispatch({ type: "create_folder_completed" });
          }, handleError);

        return () => subscription && subscription.unsubscribe();
      }
    }
  }, [isCreatingFolder, error, props.clientId, trimmedName, props.folderId, dispatch]);

  return (
    <>
      <CreateFolderHeader />
      <CreateFolderBody
        name={name}
        setName={setName}
        isCreatingFolder={isCreatingFolder}
        setIsCreatingFolder={setIsCreatingFolder}
        error={error}
      />
      <CreateFolderFooter isCreatingFolder={isCreatingFolder} setIsCreatingFolder={setIsCreatingFolder} error={error} />
    </>
  );
}

function errorCheck(trimmedName, folders) {
  let error = null;

  if (!trimmedName) {
    error = `Folder name cannot be blank!`;
  } else if (folders && folders.some((folder) => folder.name === trimmedName)) {
    error = `Folder name already exists!`;
  }

  return error;
}
