import { pluck } from "rxjs/operators";
import { fetchAsObservable } from "fetcher!sofe";

export function getClientById(id) {
  return fetchAsObservable(`
    /api/clients/${id}
  `).pipe(pluck("clients"));
}

export function getClientsByName(searchString) {
  const searchTerm = searchString.trim();
  const limit = 50;
  return fetchAsObservable(`
    api/clients/search?&limit=${limit}&search_string=${searchTerm}
  `).pipe(pluck("clients"));
}
