// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-documents-save-to-canopy-files-dropdown-rename-file-page-rename-file-footer-styles__footer--wVIB1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 6.4rem;
  padding: 0 1.6rem;
  border-top: 0.1rem solid var(--cps-color-silver)
}`, "",{"version":3,"sources":["webpack://./src/documents/save-to-canopy-files-dropdown/rename-file-page/rename-file-footer.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB;AACF","sourcesContent":[".footer {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  height: 6.4rem;\n  padding: 0 1.6rem;\n  border-top: 0.1rem solid var(--cps-color-silver)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `src-documents-save-to-canopy-files-dropdown-rename-file-page-rename-file-footer-styles__footer--wVIB1`
};
export default ___CSS_LOADER_EXPORT___;
