import React from "react";
import PropTypes from "prop-types";
import SavedConfirmationHeader from "./saved-confirmation-header.component";
import SavedConfirmationBody from "./saved-confirmation-body.component";

SavedConfirmationPage.propTypes = {
  path: PropTypes.array.isRequired,
  selectedFile: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
};

export default function SavedConfirmationPage(props) {
  return (
    <>
      <SavedConfirmationHeader path={props.path} clientId={props.clientId} selectedFile={props.selectedFile} />
      <SavedConfirmationBody selectedFile={props.selectedFile} />
    </>
  );
}
