export const FILTER_PREFIXES = {
  CLIENTS: "CON",
  // CONTACTS: "CON",
  INTERNAL: "TENANT",
  MY_FILES: "USR",
  RECENTS: "RECENTS",
};

export const SEARCH_RELEVANCE = {
  CURRENT_FOLDER: "local",
  ALL_FILES: "global",
};

export const NAVBAR_ACTIONS = {
  MORE_ACTIONS_MENU: "moreActions",
  CLIENT_PORTAL: "viewClientPortal",
  NEW_FOLDER: "newFolderBtn",
  UPLOAD: "uploadBtn",
  GLOBAL_INBOX: "globalInbox",
};

export const GLOBAL_FILES_LAYOUTS = {
  MOVE: "FileMoveLayout",
  PICKER: "FilePickerModal",
  DASHBOARD: "FilesDashboardLayout",
  ORGANIZER: "FileOrganizerFlyout",
};
