import { handleError } from "../handle-error.helper";
import { onPusher } from "fetcher!sofe";
import { filter as rxFilter, pluck, take } from "rxjs/operators";

import { FILTER_PREFIXES } from "src/global-files/resources/global-files.constants";
import { CONVERTIBLE_EXTENSIONS } from "./common.constants";
import { getFileDownloadLink, getFilePrintLink } from "./files.resource";
import { fileCategories } from "./mime-types.helpers";

export function isClientFile(file) {
  return file.id_path.replace(/\//g, "").startsWith(FILTER_PREFIXES.CLIENTS);
}

export function isMyFilesFile(file) {
  return file.id_path.replace(/\//g, "").startsWith(FILTER_PREFIXES.MY_FILES);
}

export function isInternalFile(file) {
  return file.id_path.replace(/\//g, "").startsWith(FILTER_PREFIXES.INTERNAL);
}

export function noExt(filename) {
  const extensionIndex = filename.lastIndexOf(".");
  return filename.slice(0, extensionIndex);
}

export function removeKnownExt(filename) {
  // more thorough check than noExt, uses our categories list to only remove known extensions
  const extensionIndex = filename.lastIndexOf(".");

  // if no dot or the dot is at the start, return the original filename
  if (extensionIndex <= 0) return filename;

  const extension = filename.slice(extensionIndex + 1).toLowerCase();
  const validExtensions = Object.values(fileCategories).flat();

  // check if the extension matches any in our categories list so we don't remove
  // part of the name if they use dots as separators
  return validExtensions.includes(extension) ? filename.slice(0, extensionIndex) : filename;
}

export function getExt(filename) {
  const extensionIndex = filename.lastIndexOf(".") + 1;
  return filename.slice(extensionIndex);
}

export function truncateFilename(name) {
  const ext = getExt(name);
  // The rename input is limited to 64, we will truncate to 58 to allow duplicate filename tickers up to (999)
  return noExt(name).substring(0, 58) + (ext ? "." + ext : "");
}

export function getPreviewExtension(previewLink) {
  const fileUrl = previewLink.split("?")[0];
  const extensionIndex = fileUrl.lastIndexOf(".") + 1;
  return fileUrl.slice(extensionIndex);
}

export function isFileConvertible(file) {
  const ext = getExt(file.name || "");
  return !!CONVERTIBLE_EXTENSIONS[ext];
}

export function checkFileNameForSpecialCharacters(name) {
  const specialCharactersRegex = /[\\/:*?"<>|]/;
  return specialCharactersRegex.test(name) ? true : false;
}

export const downloadFile = (fileId) => {
  // handle downloading files with fields
  getFileDownloadLink(fileId).subscribe((file) => {
    if (file?.status === "complete") {
      window.location = file.download_link;
    } else {
      listenOnDocs(fileId, "download");
    }
  }, handleError);
};

export const printFile = (fileId) => {
  // handle printing files with fields
  getFilePrintLink(fileId).subscribe((file) => {
    if (file?.status === "complete") {
      window.open(file.print_link, "_blank", "noopener=yes,noreferrer=yes");
    } else {
      listenOnDocs(fileId, "print");
    }
  }, handleError);
};

const listenOnDocs = (fileId, type) => {
  const refetchLink = () => {
    if (type === "download" && !!fileId) {
      downloadFile(fileId);
    } else if (type === "print" && !!fileId) {
      printFile(fileId);
    }
  };

  // accounts for a race condition of the file finishing converting between the two calls, so we recheck the status
  const subTimeout = setTimeout(() => {
    refetchLink();
    sub.unsubscribe?.();
  }, 10000);

  const sub = onPusher("docs")
    .pipe(
      rxFilter(
        (msg) =>
          msg.event_type === "annotated_pdf" &&
          msg.data?.annotated_pdf_status === "complete" &&
          !!msg.data?.includes_fields &&
          !msg.data?.includes_annotations &&
          msg.file_id === fileId
      ),
      take(1),
      pluck("details")
    )
    .subscribe(() => {
      refetchLink();
      clearTimeout(subTimeout);
    }, handleError);
};
