// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-documents-save-to-canopy-files-dropdown-create-folder-page-create-folder-body-styles__body--SzMDx {
  height: 11.0rem;
  padding: 1.4rem 1.6rem;
}`, "",{"version":3,"sources":["webpack://./src/documents/save-to-canopy-files-dropdown/create-folder-page/create-folder-body.styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".body {\n  height: 11.0rem;\n  padding: 1.4rem 1.6rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `src-documents-save-to-canopy-files-dropdown-create-folder-page-create-folder-body-styles__body--SzMDx`
};
export default ___CSS_LOADER_EXPORT___;
